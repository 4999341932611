import React, { useState } from "react";
//import Button from 'react-bootstrap/Button';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import ContactFormModal from './ContactFormModal';
/*<ContactFormModal  onClose={toggleModal} />*/
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    

  };
  return (
    <div className="App">
     

 
        <div className="imagen">
          <img 
            src="/images/logo-transparente.webp" 
            alt="Corazón de Fenix" 
            className="imagen-transparente" 
          />
        </div>  
        <div className="portada">
          <h2>Renace con fuerza y amor</h2>
        </div>

    



      <div className="chat-button" onClick={toggleModal}>
        💬
      </div>

      <div className={`modal ${isModalOpen ? "active" : ""}`}>
        <div className="modal-header">
          <h2 className="modal-title">Corazón de Fénix</h2>
          <p className="modal-subtitle">¡Hola! Haznos saber cómo te podemos ayudar y lo haremos lo antes posible.</p>
        </div>

        {isSubmitted ? (
          <div className="confirmation-message">
            <h3>¡Gracias por contactarnos!</h3>
            <p>Tu información ha sido recibida exitosamente. Nos pondremos en contacto contigo pronto.</p>
          </div>
          ) : (
          <ContactFormModal onClose={toggleModal} onSubmit={() => setIsSubmitted(true)} />
        )}



        
      </div>
    </div>
  );
}

export default App;