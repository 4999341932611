import React, { useState } from 'react';

function ContactFormModal({ isVisible, onClose }) {
  const [isFormVisible, setIsFormVisible] = useState(true);
  
  const [contact, setContact] = useState({
    nombre: '',
    email: '',
    telefono: '',
    mensaje: '',
  });

  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
     // Oculta los campos del formulario
    //onSubmit(); // Notifica al padre que el formulario fue envi
    setLoading(true);
    setError(null);

    try {
      // Aquí puedes configurar el Authorization token (usualmente desde el estado o un contexto global)
      const token = 'ZWZmaW5nb2NvcmF6b25kZWZlbml4'; // O bien, obtén este token dinámicamente

      const response = await fetch('/api/v1/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`, // Agregar Authorization header
        },
        body: JSON.stringify(contact),
      });

      if (!response.ok) {
        throw new Error('Error al crear el contacto');
      }

      const data = await response.json();
      console.log('Contacto creado exitosamente:', data);
      // Puedes hacer algo después de crear el contacto, como mostrar un mensaje de éxito o cerrar el modal
      setContact({
        nombre: "",
        email: "",
        telefono: "",
        mensaje: "",
      });
      setIsFormVisible(false);

      setTimeout(() => {
        setIsFormVisible(true);
        //onClose();
        
      }, 3000);

      //onClose(); // Cerrar modal después de enviar
    } catch (err) {
      setError(err.message || 'Error al crear el contacto');
    } finally {
      setLoading(false);
      
      
    }
  };

 // if (!isVisible) return null;

  return (
    <div className="form-container">
        <form onSubmit={handleSubmit} 
            autoComplete="off"
            className="form-group"
            style={{
              visibility: isFormVisible ? "visible" : "hidden",
              opacity: isFormVisible ? 1 : 0,
              transition: "opacity 0.3s ease",
            }}
        >
        <div className="form-group">
          <div className="input-container">
             
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={contact.nombre}
                onChange={handleChange}
                className="input-field" 
                placeholder=" "
                required
              />
             <label htmlFor="nombre" className="input-label">Nombre:</label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              
              <input
                type="email"
                id="email"
                name="email"
                value={contact.email}
                onChange={handleChange}
                className="input-field" 
                placeholder=" "
                required
              />
              <label htmlFor="email" className="input-label">Email:</label>
            </div>
          </div>  
          <div className="form-group">
              <div className="input-container">
               
                <input
                  type="text"
                  id="telefono"
                  name="telefono"
                  value={contact.telefono}
                  onChange={handleChange}
                  className="input-field" 
                  placeholder=" "
                  required
                />
                 <label htmlFor="telefono" className="input-label">Teléfono:</label>
            </div>
          </div>          
          <div className="form-group">
              <div className="input-container">
                
                <textarea
                  id="mensaje"
                  name="mensaje"
                  value={contact.mensaje}
                  onChange={handleChange}
                  className="input-field" 
                  placeholder=" "
                  required
                />
                <label htmlFor="mensaje" className="input-label">Mensaje:</label>
              </div>
          </div>
          <div>
            <button type="submit" disabled={loading}>
              {loading ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
        {!isFormVisible && (
          <div className="overlay">
            <h3>¡Gracias por contactarnos!</h3>
            <p>Estamos procesando tu información. Te responderemos pronto.</p>
          </div>
        )
        }        
    </div>


  );
}

export default ContactFormModal;